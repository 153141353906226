import { createI18n as _createI18n } from 'vue-i18n'
import messages from '@intlify/unplugin-vue-i18n/messages'

export const SUPPORTED_LOCALES = {
  nl: {
    code: 'nl',
    name: 'Nederlands',
  },
  en: {
    code: 'en',
    name: 'English',
  },
}

export function createI18n() {
  return _createI18n({
    locale: 'en',
    fallbackLocale: 'en',
    legacy: false,
    globalInjection: false,
    messages,
  })
}
